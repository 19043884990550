<template lang="">
    <div class="course">
        <h3 class="title">{{ $t('header.courses') }}</h3>
        <CourseList :list="courses" v-if="total" />
        <a-empty :description="$t('no_data')" v-else-if="!isLoading && !total" />
        <div class="more" @click="handleLoadMore" v-if="!isLoading && total && total > courses.length">
            <button>{{ $t('load_more') }}</button>
        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Banner from '../../components/Web/Banner/Banner.vue';
import CourseList from '../../components/Web/Courses/CourseList.vue';
import Loading from '../../components/Web/Loading/Loading.vue';
import * as CourseAPI from '../../api/courseAPI';

export default {
    components: {
        Banner,
        CourseList,
        Loading,
    },

    data() {
        return {
            isLoading: false,
            currentPage: 1,
            total: 0,
            courses: [],
        };
    },

    created() {
        this.getCourses(this.currentPage);
    },

    methods: {
        async getCourses(page) {
            try {
                this.isLoading = true;
                const res = await CourseAPI.getList({
                    fields: ['$all'],
                    order: [['created_at_unix_timestamp', 'desc']],
                    limit: 12,
                    page,
                });
                this.isLoading = false;

                const { total } = res?.pagination;
                const { rows } = res?.results?.objects;
                if (page === 1) {
                    this.total = total;
                    this.courses = rows;
                } else {
                    this.courses = this.courses.concat(rows);
                }
            } catch (error) {
                console.log('🚀 ~ file: MyPage.vue:37 ~ getCourses ~ error', error);
                this.isLoading = false;
            }
        },

        handleLoadMore() {
            const nextPage = this.currentPage + 1;
            this.currentPage = nextPage;
            this.getCourses(nextPage);
        },
    },
};
</script>

<style scoped lang="scss">
.course {
    width: 100%;
    max-width: 1170px;
    margin: 80px auto;
    padding: 0 15px;
}

.title {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    text-align: left;
}

.more {
    margin-top: 60px;
    text-align: center;

    & button {
        padding: 14px 38px;
        background-color: #000;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        border-radius: 4px;
    }
}

@media screen and (max-width: 767px) {
    .title {
        margin-bottom: 20px;
        font-size: 24px;
    }
}
</style>
